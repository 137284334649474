import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { useRef } from 'react';
import ReactHtmlTableToExcel from 'react-html-table-to-excel';
import { toast } from 'react-toastify';
import Loader from '../../../Shared/Loader';
import logo from '../../../../assets/images/logo.jpg';
import { useReactToPrint } from 'react-to-print';
import BusinessInfo from '../../../Shared/BusinessInfo';

const BankStatement = () => {
    const [isLoaded, setIsLoaded] = useState(false)
    const [bankstatement, setBankStatement] = useState([]);
    const [bankAccounts, setBankAccounts] = useState([]);
    const [opening, setOpening] = useState(0);
    const [details, setDetails] = useState('');
    const [isFilter, setIsFilter] = useState(false);
    // const [loading, setLoading] = useState(true);
    const componentRef = useRef();

    console.log(details);
    const date = new Date();
    const today = format(date, "yyyy-MM-dd");


    useEffect(() => {
        fetch('https://expressmartbd.com/expressmartpos/getbankaccounts')
            .then(res => res.json())
            .then(data => {
                setBankAccounts(data);
                // setLoading(false)
            })
    }, [])

    const handleFilteredData = e => {
        e.preventDefault();
        const startDate = e.target.startDate.value;
        const endDate = e.target.endDate.value;
        // const acccountValue = e.target.account.value;
        // const startIndex = acccountValue.indexOf('(', acccountValue.indexOf('(') + 1) + 1;
        // const endIndex = acccountValue.indexOf(')', startIndex);
        // const accountno = acccountValue.substring(startIndex, endIndex);
        // const endIndex2 = acccountValue.indexOf(`(${accountno})`);
        // const accountName = acccountValue.substring(0, endIndex2);

        const acccountValue = e.target.account.value;
        const lastIndex = acccountValue.lastIndexOf('(');
        const endIndex = acccountValue.lastIndexOf(')');

        let accountno;
        let accountName;

        if (lastIndex !== -1 && endIndex !== -1 && lastIndex < endIndex) {
             accountno = acccountValue.substring(lastIndex + 1, endIndex);
             accountName = acccountValue.substring(0, lastIndex);
            console.log(accountno); // This will give you the value inside the last ()
            console.log(accountName); // This will give you the text before the last ()
        } else {
            // Handle the case where there are no () or they are not in the right order.
        }

        const options = {
            timeZone: 'Asia/Dhaka',
            hour12: false,
        };
        const currentTime = new Date().toLocaleString('en-US', options);


        const url = `https://expressmartbd.com/expressmartpos/filteredbankstatement?startDate=${startDate}&endDate=${endDate}&accountno=${accountno}`
        fetch(url)
            .then(res => res.json())
            .then(data => {
                const sorted = data.sort(function (a, b) {
                    return (a?.date > b?.date) ? 1 : ((a?.date < b?.date) ? -1 : 0);
                });
                setBankStatement(sorted);
                setIsFilter(true);
                setIsLoaded(true);
                setDetails({ startDate: startDate, accountno: accountno, accountName: accountName, range: startDate + " to " + endDate, time: currentTime });
                // setLoading(false)
            })
        const url2 = `https://expressmartbd.com/expressmartpos/filteredbankopeningbalance?startDate=${startDate}&accountno=${accountno}`
        console.log(url2);
        fetch(url2)
            .then(res => res.json())
            .then(data => {
                const totalIn = data.reduce((total, currentValue) => total + (currentValue.totalIncomes || 0), 0);
                const totalOut = data.reduce((total, currentValue) => total + (currentValue.totalExpenses || 0), 0);
                const opening = data.reduce((total, currentValue) => total + (currentValue.opening || 0), 0);
                const finalBalance = opening + totalIn - totalOut;
                setOpening(finalBalance);
            })
    }

    useEffect(() => {
        fetch("https://expressmartbd.com/expressmartpos/openingbalance")
            .then(res => res.json())
            .then(data => {
                const totalIn = data.reduce((total, currentValue) => total + (currentValue.totalIncomes || 0), 0);
                const totalOut = data.reduce((total, currentValue) => total + (currentValue.totalExpenses || 0), 0);
                const finalBalance = totalIn - totalOut;
                setOpening(finalBalance);

            })
    }, [])


    const totalIncome = bankstatement.reduce((total, currentValue) => total + parseInt(currentValue.income), 0);
    const totalExpense = bankstatement.reduce((total, currentValue) => total + parseInt(currentValue.expense), 0);

    // if (loading) {
    //     return <Loader />
    // }
    const generatePrint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: `Bank Statement Of ${details?.accountName}`,

    })
    return (
        <div className='mx-2'>
            <h2 className='mt-8 text-center text-lg font-bold'>Bank Statement Report</h2>

            <div className='flex justify-center items-center'>
                <form onSubmit={handleFilteredData} className='mt-12 flex flex-col lg:flex-row justify-center items-center'>
                    <div class="form-control w-full mx-auto lg:w-40 max-w-xs lg:mr-2">
                        <label class="label">
                            <span class="label-text">Starting Date</span>
                        </label>
                        <input name='startDate' defaultValue={today} type="date" class="input text-xs input-bordered w-full max-w-xs" required />
                    </div>
                    <div class="form-control w-full mx-auto lg:w-40 max-w-xs lg:mr-2">
                        <label class="label">
                            <span class="label-text">End Date</span>
                        </label>
                        <input name='endDate' defaultValue={today} type="date" class="input text-xs input-bordered w-full max-w-xs" required />
                    </div>
                    <div class="form-control w-80 max-w-xs lg:mr-2">
                        <label class="label">
                            <span class="label-text">Account Name</span>
                        </label>
                        <select name='account' defaultValue='' class="rounded-lg text-black text-xs select select-bordered w-full max-w-xs" required>
                            {
                                bankAccounts.map(account => <option>{account.name}({account.accountno})</option>)
                            }
                        </select>
                    </div>
                    <input type='submit' value='Search' className='btn btn-md btn-error w-80 mx-auto lg:w-20 text-white mt-4 lg:mt-9 lg:ml-2' />
                    <div>
                    </div>
                </form>
            </div>
            {isLoaded && <div className='text-right'>
                <ReactHtmlTableToExcel
                    id="test-table-xls-button"
                    className="btn btn-error text-white btn-xs mt-2 lg:mt-0"
                    table="table-to-xls"
                    filename={`Bank Statement- ${details.accountName}`}
                    sheet={`bankstatement-${details.accountName}`}
                    buttonText="Export to exel" />
                <button className='btn btn-success btn-xs ml-2 text-white' onClick={generatePrint}>Print</button>
            </div>
            }
            {isLoaded && <div ref={componentRef}>
                <div className='flex justify-center items-center mt-8'>
                    <div>
                        <img src={BusinessInfo.img} className="w-20 lg:w-44 lg:mr-20 lg:mb-4" alt="" />
                    </div>
                    <div className='lg:mr-40'>
                        <h1 className='text-xl lg:text-4xl text-black text-center'>{BusinessInfo.fullName}</h1>
                        <h1 className='text-sm lg:text-xl text-black text-center'>{BusinessInfo.dealer}</h1>
                        <h1 className='text-xs text-black text-center'>{BusinessInfo.address}</h1>
                        <h1 className='text-xs lg:text-xl text-black text-center'>{BusinessInfo.mobile}</h1>
                    </div>
                </div>
                <div class="flex items-center justify-between">
                    <hr class="border-b border-gray-300 w-full mt-4 mb-px" />
                    <hr class="border-b border-gray-300 w-full mt-4 mb-px" />
                </div>
                <div class="flex items-center justify-between">
                    <hr class="border-b border-gray-300 w-full my-px" />
                    <hr class="border-b border-gray-300 w-full my-px" />
                </div>

                <div className='text-left ml-20 mt-8 text-xs'>
                    <h4>Report Type: <span className='font-bold'>Bank Statement</span></h4>
                    <h4>Account Name: {details?.accountName}</h4>
                    <h4>Account No: {details?.accountno}</h4>
                    <h4>Report Date Range: {details?.range}</h4>
                    <h4>Report Time: {details?.time}</h4>
                </div>

                <div className='mb-20'>
                    <div class="overflow-x-auto mt-8  text-xs">
                        <table id='table-to-xls' class="table w-full text-center shadow-xl rounded-lg">
                            <thead>
                                <tr>
                                    <th className='bg-red-600 text-white normal-case'>SL</th>
                                    <th className='bg-red-600 text-white normal-case'>Date</th>
                                    <th className='bg-red-600 text-white normal-case'>Invoice No</th>
                                    <th className='bg-red-600 text-white normal-case'>Name</th>
                                    <th className='bg-red-600 text-white normal-case'>Type</th>
                                    <th className='bg-red-600 text-white normal-case'>Deposit Amount</th>
                                    <th className='bg-red-600 text-white normal-case'>Withdraw Amount</th>
                                    <th className='bg-red-600 text-white normal-case'>Details</th>
                                </tr>

                            </thead>
                            <tbody>
                                {
                                    bankstatement.map((income, index) => <tr>
                                        <td className='border border-red-600'>{index + 1}</td>
                                        <td className='border border-red-600'>{income.date}</td>
                                        <td className='border border-red-600'>{income.invoice}</td>
                                        <td className='border border-red-600'>{income.name}</td>
                                        <td className='border border-red-600'>{income.type}</td>
                                        <td className='border border-red-600'>{income.expense === 0 ? "-" : income.expense}</td>
                                        <td className='border border-red-600'>{income.income === 0 ? "-" : income.income}</td>
                                        <td className='border border-red-600'>{income.details}</td>
                                    </tr>)
                                }

                            </tbody>
                            <tfoot>
                                <tr>
                                    <th className='bg-red-600 text-white normal-case'></th>
                                    <th className='bg-red-600 text-white normal-case'>Opening Balance On</th>
                                    <th className='bg-red-600 text-white normal-case'>{details?.startDate}</th>
                                    <th className='bg-red-600 text-white text-sm'>{opening}</th>
                                    <th className='bg-red-600 text-white text-sm normal-case'>Total</th>
                                    <th className='bg-red-600 text-white text-sm'>{totalExpense}</th>
                                    <th className='bg-red-600 text-white text-sm'>{totalIncome}</th>
                                    <th className='bg-red-600 text-white text-sm normal-case'>Closing: {opening + totalExpense - totalIncome}</th>
                                </tr>
                            </tfoot>
                        </table>
                    </div>

                </div>

            </div>}


            {/* {isLoaded && <div ref={componentRef} style={{width:"100%"}} className='flex justify-center items-center mt-8'>
                <div>
                    <img src={logo} className="w-72 mr-20" alt="" />
                </div>
                <div className='mr-40'>
                    <h1 className='text-6xl text-black text-center'>M/S Nazmul Electric and Electronics</h1>
                    <h1 className='text-3xl text-black text-center'>Mawna Chowrasta, Sreepur, Gazipur</h1>
                    <h1 className='text-xl text-black text-center'>Mobile: 01404010532,01404010533</h1>
                </div>
            </div>}
            {isLoaded && <div class="flex items-center justify-between">
                <hr class="border-b border-gray-300 w-full mt-4 mb-px" />
                <hr class="border-b border-gray-300 w-full mt-4 mb-px" />
            </div>}
            {isLoaded && <div class="flex items-center justify-between">
                <hr class="border-b border-gray-300 w-full my-px" />
                <hr class="border-b border-gray-300 w-full my-px" />
            </div>}
            {isLoaded && <div className='text-left'>
                <h4>Report Type: Bank Statement</h4>
                <h4>Account Name: {details?.accountName}</h4>
                <h4>Account No: {details?.accountno}</h4>
                <h4>Report Date Range: {details?.range}</h4>
                <h4>Report Time: {details?.time}</h4>
            </div>}
            {isLoaded && <div className='text-right'>
                <ReactHtmlTableToExcel
                    id="test-table-xls-button"
                    className="btn btn-error text-white btn-xs mt-10 lg:mt-0"
                    table="table-to-xls"
                    filename={`Bank Statement- ${details.accountName}`}
                    sheet={`bankstatement-${details.accountName}`}
                    buttonText="Export to exel" />
            </div>}
            {isLoaded && <div className='mb-20'>
                <div class="overflow-x-auto mt-8 text-xs">
                    <table id='table-to-xls' class="table w-full text-center shadow-xl rounded-lg">
                        <thead>
                            <tr>
                                <th className='bg-red-600 text-white normal-case'>SL</th>
                                <th className='bg-red-600 text-white normal-case'>Date</th>
                                <th className='bg-red-600 text-white normal-case'>Invoice No</th>
                                <th className='bg-red-600 text-white normal-case'>Name</th>
                                <th className='bg-red-600 text-white normal-case'>Type</th>
                                <th className='bg-red-600 text-white normal-case'>Deposit Amount</th>
                                <th className='bg-red-600 text-white normal-case'>Withdraw Amount</th>
                                <th className='bg-red-600 text-white normal-case'>Details</th>
                            </tr>

                        </thead>
                        <tbody>
                            {
                                bankstatement.map((income, index) => <tr>
                                    <td className='border border-red-600'>{index + 1}</td>
                                    <td className='border border-red-600'>{income.date}</td>
                                    <td className='border border-red-600'>{income.invoice}</td>
                                    <td className='border border-red-600'>{income.name}</td>
                                    <td className='border border-red-600'>{income.type}</td>
                                    <td className='border border-red-600'>{income.income === 0 ? "-" : income.income}</td>
                                    <td className='border border-red-600'>{income.expense === 0 ? "-" : income.expense}</td>
                                    <td className='border border-red-600'>{income.details}</td>
                                </tr>)
                            }

                        </tbody>
                        <tfoot>
                            <tr>
                                <th className='bg-red-600 text-white normal-case'></th>
                                <th className='bg-red-600 text-white text-sm'>Opening Balance On</th>
                                <th className='bg-red-600 text-white text-sm'>{details?.startDate}</th>
                                <th className='bg-red-600 text-white text-sm'>{opening}</th>
                                <th className='bg-red-600 text-white text-sm normal-case'>Total</th>
                                <th className='bg-red-600 text-white text-sm'>{totalIncome}</th>
                                <th className='bg-red-600 text-white text-sm'>{totalExpense}</th>
                                <th className='bg-red-600 text-white text-sm normal-case'>Closing: {opening + totalIncome - totalExpense}</th>
                            </tr>
                        </tfoot>
                    </table>
                </div>

            </div>} */}
        </div>
    );
};

export default BankStatement;
import logo from '../../assets/images/logo.jpg'

const BusinessInfo = {
    name: "Expressmartbd.com",
    fullName: "ExpressMartBD.Com",
    dealer: "(Quality Product At Your DoorStep)",
    address: "Mawna Chowrasta, Sreepur, Gazipur",
    mobile: "Mobile: 01772545974,01310751276",
    img: logo,
}

export default BusinessInfo;
